import { Injectable, signal } from '@angular/core';
import { UserInfo } from '@longnecktech/splash-commons-fe';
import { Theme } from '@shared/types/theme';
import { Game, GameState, QuestionState } from '@shared/types/game';
import { InfoContentDialogType } from '@shared/types/info-content-dialog-type';
import packageInfo from '../../../package.json';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';
import { GAME_STATE } from '@shared/constants/local-storage-keys.consts';
import { Question, QuestionType } from '@shared/types/question';
import { Transaction } from '@shared/types/transaction';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private _isMobile = new BehaviorSubject<boolean>(true);
  private _hasResults = new BehaviorSubject<boolean>(false);
  private _isOpenedInfoContentDialog = new BehaviorSubject<boolean>(false);
  private _infoContentDialogType = new BehaviorSubject<
    InfoContentDialogType | undefined
  >(undefined);
  thirdPartyToken = '';
  theme?: Theme;
  version = '0.0.0';
  user = signal<UserInfo | undefined>(undefined);
  game = signal<Game | undefined>(undefined);
  currentQuestionId = signal<number>(0);
  gameSubmitted = signal<boolean>(false);
  togglesPicked = signal<number>(0);
  transaction = signal<Transaction | undefined>(undefined);
  ticketCount = 1;
  stakeValue = 0;
  instance?: string;
  gameUuid?: string;
  gamePlayed = false;
  hasCurrentGameResults = false;
  numberOfQuestionsTotal = 0;
  startGameUserAction = false;

  isMobile$ = this._isMobile.asObservable();
  hasResults$ = this._hasResults.asObservable();
  isOpenedInfoContentDialog$ = this._isOpenedInfoContentDialog.asObservable();
  infoContentDialogType$ = this._infoContentDialogType.asObservable();

  constructor(private localStorageService: LocalStorageService) {
    this.version = packageInfo.version;
  }

  updateIsMobile(isMobile: boolean): void {
    this._isMobile.next(isMobile);
  }

  updateHasResult(result: boolean): void {
    this._hasResults.next(result);
  }

  updateContentDialogState(isOpened: boolean): void {
    this._isOpenedInfoContentDialog.next(isOpened);
  }

  updateInfoContentDialogType(type: InfoContentDialogType): void {
    this._infoContentDialogType.next(type);
  }

  updateCorrectScoreInStorage(
    questionUuid: string,
    competitor1Score: number,
    competitor2Score: number,
  ): void {
    const gameState = this.localStorageService.getItem<GameState[]>(GAME_STATE);
    this.localStorageService.setItem(
      GAME_STATE,
      gameState!.map((game) => {
        if (game.uuid === this.game()!.uuid) {
          return {
            ...game,
            questions: {
              ...game.questions,
              [questionUuid]: {
                optionUuids: [],
                competitor1Score,
                competitor2Score,
              },
            },
          };
        }
        return game;
      }),
    );
  }

  updateOptionsInStorage(questionUuid: string, optionUuid: string): void {
    const gameState = this.localStorageService.getItem<GameState[]>(GAME_STATE);
    this.localStorageService.setItem(
      GAME_STATE,
      gameState!.map((game) => {
        if (game.uuid === this.game()!.uuid) {
          return {
            ...game,
            questions: {
              ...game.questions,
              [questionUuid]: this.updateOptions(
                game.questions && game.questions[questionUuid],
                optionUuid,
              ),
            },
          };
        }
        return game;
      }),
    );
  }

  updateCurrentQuestionInStorage(questionID: number): void {
    const gameState = this.localStorageService.getItem<GameState[]>(GAME_STATE);
    this.localStorageService.setItem(
      GAME_STATE,
      gameState!.map((game) => {
        if (game.uuid === this.game()!.uuid) {
          return {
            ...game,
            currentQuestionId: questionID,
          };
        }
        return game;
      }),
    );
  }

  setCurrentGame(game: Game): void {
    this.theme = game.theme;
    this.stakeValue = game.stakeValue;
    if (game.ended || game.paused || this.hasCurrentGameResults) {
      this.game.set(game);
      this.numberOfQuestionsTotal = game.questions.length;
    } else {
      this.setCurrentGameFromStorage(game);
    }
  }

  updateDataOnSubmittingPicks(): void {
    this.removeGameFromStorage();
    this.currentQuestionId.set(0);
    this.updateHasResult(true);
    this.hasCurrentGameResults = true;
    if (this.game()) {
      this.stakeValue = this.game()!.stakeValue;
    }
  }

  removeGameFromStorage(): void {
    const gameState = this.localStorageService.getItem<GameState[]>(GAME_STATE);
    if (gameState) {
      this.localStorageService.setItem(
        GAME_STATE,
        gameState.filter((game) => game.uuid !== this.game()!.uuid),
      );
    }
  }

  private setCurrentGameFromStorage(game: Game): void {
    const gameState = this.localStorageService.getItem<GameState[]>(GAME_STATE);
    if (!gameState) {
      // set this game to the local storage as a first game
      this.game.set(game);
      this.numberOfQuestionsTotal = game.questions.length;
      this.localStorageService.setItem(GAME_STATE, [
        {
          uuid: game.uuid,
          currentQuestionId: this.currentQuestionId(),
          questions: {},
        },
      ]);
    } else {
      // set this game to the existing game state only in the case if there is no this game there
      const currentGame = gameState.find(
        (state) => state.uuid === game.uuid,
      ) as GameState;
      if (!currentGame) {
        this.game.set(game);
        this.numberOfQuestionsTotal = game.questions.length;
        this.localStorageService.setItem(GAME_STATE, [
          // we want to store no more than 20 games in the store
          ...(gameState.length === 20 ? gameState.slice(1) : gameState),
          {
            uuid: game.uuid,
            currentQuestionId: this.currentQuestionId(),
            questions: {},
          },
        ]);
      } else {
        // update the current question id from the local storage
        this.currentQuestionId.set(
          currentGame.questions
            ? currentGame.currentQuestionId
            : this.currentQuestionId(),
        );
        // update selected options
        this.numberOfQuestionsTotal = game.questions.length;
        this.game.set({
          ...game,
          questions: this.setPickedOptions(
            game.questions,
            currentGame.questions,
          ),
        });
      }
    }
  }

  private updateOptions(
    questionState: QuestionState,
    optionUuid: string,
  ): QuestionState {
    if (!questionState) {
      return {
        optionUuids: [optionUuid],
        competitor1Score: 0,
        competitor2Score: 0,
      };
    }
    const pickedOptions = questionState.optionUuids;
    return {
      ...questionState,
      optionUuids: pickedOptions.includes(optionUuid)
        ? pickedOptions.filter((option) => option !== optionUuid)
        : [...pickedOptions, optionUuid],
    };
  }

  private setPickedOptions(
    questions: Question[],
    questionsFromStorage: Record<string, QuestionState>,
  ): Question[] {
    // this case is possible for previous version of the game state in the local storage
    if (!questionsFromStorage) return questions;
    // if there are options that were picked and set to the local storage then
    // we have to set it on loading the game
    return questions.map((question) => {
      const dataFromStorage = questionsFromStorage[question.uuid];
      if (!dataFromStorage) return question;
      if (question.type === QuestionType.Options) {
        return {
          ...question,
          options: !dataFromStorage
            ? question.options
            : question.options.map((option) => {
                return {
                  ...option,
                  picked: dataFromStorage.optionUuids.includes(option.uuid),
                };
              }),
        };
      }

      if (question.type === QuestionType.CorrectScore) {
        return {
          ...question,
          competitor1Score: dataFromStorage.competitor1Score,
          competitor2Score: dataFromStorage.competitor2Score,
        };
      }
      return question;
    });
  }
}
